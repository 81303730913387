import React from 'react';
import classNames from 'classnames';
import { string, bool, number, shape } from 'prop-types';
import { Button } from '@andes/button';
import Image from '../../../../commons/image';

const LoyaltyBuyLevel6DesktopHeader = (props) => {
  const {
    title,
    pricing,
    gradient_color,
    logo,
    benefits_description,
    is_evolution,
    action,
  } = props;

  const { from, currency, time, price } = pricing ?? {};

  const DEG_LOYALTY = gradient_color?.deg ?? '90deg';
  const FIRST_COLOR_LOYALTY = gradient_color?.first_color ?? '#a90f90';
  const PERCENTAGE_LOYALTY = gradient_color?.percentage ?? '55%';
  const SECOND_COLOR_LOYALTY = gradient_color?.second_color ?? '#0c1a51';

  const PricesValues = () => (
    <span className="loyalty-buylevel6__header__title-price">
      {from && (
        <div className="loyalty-buylevel6__header__title-preprice--strike">
          {from}
        </div>
      )}
      {currency}
      <span className="loyalty-buylevel6__header__title-price__value">
        {price?.cents ? `${price?.units},${price?.cents}` : price?.units}
        {time}
      </span>
    </span>
  );

  return (
    <div
      className="loyalty-buylevel6__header"
      style={{
        '--deg_loyalty': DEG_LOYALTY,
        '--first_color_loyalty': FIRST_COLOR_LOYALTY,
        '--percentage_loyalty': PERCENTAGE_LOYALTY,
        '--second_color_loyalty': SECOND_COLOR_LOYALTY,
      }}
    >
      <div className="loyalty-buylevel6__header__title">
        {logo && (
          <Image
            {...logo}
            className="loyalty-buylevel6__header__title-img"
            lazyLoad
            alt={logo.alt}
          />
        )}
        <div
          className={classNames('loyalty-buylevel6__header__title-text', {
            'loyalty-buylevel6__header__title-text--no-evolution':
              !is_evolution,
          })}
        >
          {title}
          {pricing && <PricesValues />}
        </div>
      </div>
      {action && (
        <div className="loyalty-buylevel6__action">
          <span className="loyalty-buylevel6__action-title">
            {benefits_description}
          </span>
          <Button
            className="loyalty-buylevel6__action-button"
            hierarchy="quiet"
            href={action.target}
            size="medium"
          >
            {action.label?.text}
          </Button>
        </div>
      )}
    </div>
  );
};

LoyaltyBuyLevel6DesktopHeader.propTypes = {
  title: string,
  pricing: shape({
    description: string,
    is_feature_on: bool,
    is_only_pill: bool,
    from: string,
    currency: string,
    time: string,
    price: shape({
      units: string,
      cents: number,
    }),
    is_pill_description_on: bool,
    show_divider: bool,
    from_label: string,
    pill_discount: shape({
      label: string,
    }),
  }),
  gradient_color: shape({
    deg: string,
    first_color: string,
    percentage: string,
    second_color: string,
  }),
  logo: shape({
    alt: string,
    src: string,
  }),
  is_evolution: bool,
  action: shape({
    target: string,
    label: shape({
      text: string,
    }),
  }),
  benefits_description: string,
};

export default LoyaltyBuyLevel6DesktopHeader;
