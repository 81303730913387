import React from 'react';
import { string, shape, arrayOf } from 'prop-types';
import Section from '../../../../commons/section';
import withTracker from '../../../../commons/with-tracker';
import BenefitItem from '../benefit-item';
import LoyaltyBuyLevel6DesktopHeader from './header';

const LoyaltyBuyLevel6Desktop = ({
  header,
  benefits,
  benefits_title,
  action,
}) => (
  <Section className="loyalty-buylevel6">
    <div className="row container">
      <div className="loyalty-buylevel6__wrapper">
        <LoyaltyBuyLevel6DesktopHeader {...header} action={action} />
        <div className="loyalty-buylevel6__body">
          <span className="loyalty-buylevel6__body-title">
            {benefits_title}
          </span>
          <div className="loyalty-buylevel6__body-benefits">
            {benefits.map((item) => (
              <BenefitItem {...item} key={item.description} />
            ))}
          </div>
        </div>
      </div>
    </div>
  </Section>
);

LoyaltyBuyLevel6Desktop.propTypes = {
  header: shape(LoyaltyBuyLevel6DesktopHeader.propTypes),
  benefits: arrayOf(
    shape({
      icon: shape({
        url: shape({
          src: string,
        }),
      }),
      description: string,
    }),
  ),
  benefits_title: string,
  action: shape({
    label: shape({}),
    target: string,
  }),
};

export default withTracker(LoyaltyBuyLevel6Desktop);
